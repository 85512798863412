.footer{
    background-color: #191918;
    padding: 50px var(--alinhamento-horizontal);
}

.footer_main{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer_logo{
    width: 200px;
    opacity: 0;
    transform: translateY(100%);
}

.footer_content_item{
    color: white;
}
.footer_content_item h4{
    margin: 0;
    opacity: 0;
    transform: translateY(200%);
}
.footer_content_item ul{
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

.footer_content_item li{
    font-weight: 200;
    margin: 5px;
    opacity: 0;
    transform: translateX(-50%);
}

.footer_content_item li:last-child{
    margin-bottom: 0;
}

.animation_vertical{
    animation: animation_vertical 1s ease-out forwards;
}

.animation_horizontal{
    animation: animation_horizontal 1s ease-out forwards;
}

@keyframes animation_horizontal{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes animation_vertical{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        transform: translateX(0);
        opacity: 1;
    }
}

@media screen and (max-width:900px) {

    .footer_logo{
        width: 180px;
    }

}

@media screen and (max-width:800px) {

    .footer_main{
        flex-direction: column;
        align-items: flex-start;
    }

    .footer_content_item{
        margin: 40px 0 0;
    }

}