.atuacao{
    background-color: var(--background-cinza);
    padding: var(--alinhamento-vertical) var(--alinhamento-horizontal);
}

.atuacao_item_titulo{
    font-weight: bold;
}

.atuacao_titulo h2{
    margin-top: 0;
    opacity: 0;
}

.atuacao_titulo p{
    opacity: 0;
}

.atuacao_content .atuacao_content_item:last-child{
    margin-bottom: 0;
}

.atuacao_content_item{
    display: flex;
    flex-direction: column;
    margin: 60px 0;
    opacity: 0;
}
.atuacao_item_subtitulo{
    width: 50%;
    background-color: var(--background-branco);
    padding: 20px;
    border-radius: 20px;
}

.atuacao_item_titulo_container{
    display: flex;
    align-items: center;
}

.atuacao_item_titulo{
    margin: 0 0 0 10px;
    flex: 1;
}

.atuacao_item_desc{
    display: grid;
    grid-template-columns: 2.5rem 1fr 0.3fr;
    align-items: center;
}

.arrow{
    font-size: 2.5rem;
    margin: 0 20px;
}

.atuacao_item_subtitulo{
    margin-left: calc(2.5rem + 10px);
}

.animation_opacity{
    animation: animation_opacity 1s ease-out forwards;
}

@keyframes animation_opacity{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
}

@media screen and (max-width:900px) {

    .atuacao_item_desc{
        grid-template-columns: 2.5rem 1fr 0.1fr;
        
    }

    .atuacao_item_subtitulo{
        width: 70%;
        background-color: var(--background-branco);
        padding: 20px;
        border-radius: 20px;
    }

}

@media screen and (max-width:470px) {

    .atuacao_item_desc{
        grid-template-columns: 2.5rem 1fr;
        
    }

    .atuacao_item_subtitulo{
        width: 70%;
        background-color: var(--background-branco);
        padding: 20px;
        border-radius: 20px;
    }

}