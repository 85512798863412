.etapas{
    background: url(../../assets/image/ceu_estrelado.jpg) top center/ cover no-repeat;
}

.etapas_main{
    background-color: rgba(0, 0, 0, 0.6);
    padding: var(--alinhamento-vertical) var(--alinhamento-horizontal);
}
.etapas_main_text_content{
    background-color: transparent;
}

.etapas_main h2{
    color: white;
    transform: translateX(-30%);
    opacity: 0;
}

.etapas_main h3{
    margin-top: 0;
    transform: translateX(-30%);
    opacity: 0;
}

.etapas_main_content{
   display: grid;
   grid-template-columns: 20px 1fr;
}

.etapa_span{
    height: 100%;
    width: 7px;
    grid-column: 1/2;
    background: var(--linear-gradient-dourado-180);
}

.etapas_content{
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.etapas_content h4{
    margin-top: 0;
}

.etapas_item h4, .etapas_item p{
    color: white;
    transform: translateX(-30%);
    opacity: 0;
}

.etapas_item_invertido_text{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
}

.etapas_item_invertido_text p{
    margin-top: 0;
    
}

.etapas_item_invertido p, .etapas_item p{
    width: 50%;
}

.etapas_item_invertido h4, .etapas_item_invertido p{
    color: white;
    text-align: right;
    transform: translateX(-30%);
    opacity: 0;
}

.etapas_item, .etapas_item_invertido{
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.etapas_item div:not(.etapas_item_icon) {
    flex: 1;
}

.etapas_item_icon{
    display: flex;
    width: 7rem;
    height: 7rem;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 3rem;
    color: var(--main-dourado_1);
    transform: translateX(-30%);
    opacity: 0;
}

.animation_vertical{
    animation: animation_vertical 1s ease-out forwards;
}

@keyframes animation_vertical{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        transform: translateX(0);
        opacity: 1;
    }
}


.etapas_item_icon svg:not(.etapas_item_icon_content){
    position: absolute;
    
}

@media screen and (max-width: 900px) {

    .etapas_item_invertido p, .etapas_item p{
        width: 100%;
    }
}