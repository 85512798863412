:root {
    --main-dourado_1: #A7834F;
    --main-dourado_2: #F3E9A2;
    --background-cinza: #EBE9E9;
    --background-branco: #ffffff;
    --linear-gradient-dourado-90: linear-gradient(90deg , var(--main-dourado_1), var(--main-dourado_2), var(--main-dourado_1));
    --linear-gradient-dourado-180: linear-gradient(180deg , var(--main-dourado_1), var(--main-dourado_2), var(--main-dourado_1));
    --main-dourado_2: #F3E9A2;
    --alinhamento-horizontal: 10vw;
    --alinhamento-vertical: 100px;
  }

body{
    margin: 0;
}

html {
  scroll-behavior: smooth;
}

h1, h2, h4{
  font-family: "Montserrat", sans-serif;
}

h3, p, li, a, label, span{
  font-family: "Work Sans", sans-serif;
}

p{
  font-size: 1.1rem;
  text-align: justify;
}

h2{
  font-size: 1.8rem;
}

h3{
  letter-spacing: 3px;
  color: var(--main-dourado_1);
}

button{
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
  font-size: 1rem;
}

@media screen and (max-width: 1100px) {
  :root {
    --alinhamento-horizontal: 5vw;
  }
  html{
    font-size: 15px;
  }
}

@media screen and (max-width: 970px) {
  :root {
    --alinhamento-horizontal: 5vw;
  }
  html{
    font-size: 14px;
  }
}

@media screen and (max-width:590px) {
  :root {
    --alinhamento-vertical: 60px;
  }

  html{
    font-size: 13px;
  }
}



