.bloco{
    padding: var(--alinhamento-vertical) var(--alinhamento-horizontal);
}

.bloco_main{
    display: flex;
    align-items: center;
}

.bloco_content{
    width: 50%;
    margin-right: 40px;
}

.bloco_content h3{
    margin-top: 0;
    transform: translateY(100%);
    opacity: 0;
}
.bloco_content h2{
    transform: translateY(100%);
    opacity: 0;
}

.bloco_content_p1{
    transform: translateY(100%);
    opacity: 0;
}

.bloco_content_p2{
    transform: translateY(50%);
    opacity: 0;
}

.bloco_content_p3{
    transform: translateY(130%);
    opacity: 0;
}

.bloco_content_image_div{
    width: 50%;
}

.bloco_content_image{
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    transform: translateY(50%);
    opacity: 0;
}

.animation_horizontal{
    animation: animation_horizontal 1s ease-out forwards;
}

@keyframes animation_horizontal{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}

@media screen and (max-width:900px) {

    .bloco_main{
    flex-direction: column;
    }
    .bloco_content{
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }
    .bloco_content_image_div{
        width: 100%;
    }
}