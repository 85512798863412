.bloco{
    position: relative;
    padding: 20px  var(--alinhamento-horizontal) var(--alinhamento-vertical);
}

.bloco_main{
    display: flex;
    align-items: center;
}

.bloco_content{
    width: 50%;
    margin-right: 40px;
}

.bloco_content h3{
    margin-top: 0;
    
}

.bloco_content_image_div{
    width: 50%;
}

.bloco_content_image{
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.input_container, .text_area_container{
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.text_area_container{
    margin: 0;
}

.input_container_input, .text_area{
    height: 50px;
    width: 95%;
    border: 1px solid #EBE9E9;
    outline: none;
    color: #000000;
    font-weight: 400;
    font-size: 16px;
    font-family: "Work Sans", sans-serif;
    line-height: 20px;
    padding: 0 0 0 10px;
    border-radius: 10px;
    background-color: #EBE9E9;
    margin-bottom: 20prgb(161, 161, 161)
}

.text_area{
    height: 150px;
    resize: none;
    padding-top: 20px;
}



.input_container_input:focus, .text_area:focus{
      border: 1px dotted #000000;
      background-color: #ffffff;

}

.input_container_label{ 
    padding: 15px 0 0 10px;
    position: absolute;
    left: 0;
    color: #424242;
    font-weight: 600;
    transform-origin: 0 0;
    transition: 0.2s ease-in-out;
    pointer-events: none;
}

.input_invalido .input_container_label{ 
    color: rgb(216, 22, 22);
}

.input_container_input:focus + .input_container_label,
.input_container_input:not(:placeholder-shown) + .input_container_label, .text_area:focus + .input_container_label,
.text_area:not(:placeholder-shown) + .input_container_label{
    transform: translateY(-7px) scale(0.7);
    padding-left: 12px;
    color: #000000;
}

.contato_form{
    background-color: white;
}


.span_de_confirmacao{
    position: absolute;
    top: 50%;
    left: 35%;
    right: 35%;
    text-align: center;
    box-sizing: border-box;
    background-color: white;
    padding: 15px 25px;
    border-radius: 30px;
    opacity: 0;
    box-shadow: 3px 3px 8px rgb(145, 145, 145);
}
.input_container_span_error {
    color: #c70d0d;
    font-weight: 500;
    margin-top: 10px;
    margin-left: 10px;
}

.form_button{
    padding: 10px 30px;
    background-color: transparent;
    border: 1px solid black;
    margin-top: 40px;
}

.form_button:hover{
    background: var(--linear-gradient-dourado-90);
    color: black;
    border-color: transparent;
    font-size: 1em;
    transition: background-color 0.2s linear;
}

.animation_horizontal{
    animation: animation_horizontal 1s ease-out forwards;
}

.animation_opacity{
    animation: animation_opacity 1s ease-out forwards;
}

.animation_opacity_reverse{
    animation: animation_opacity_reverse 1s ease-out forwards;
}



@keyframes animation_horizontal{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes animation_opacity{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
}

@keyframes animation_opacity_reverse{
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 0;
    }
}

@media screen and (max-width:900px) {

    .bloco_main{
    flex-direction: column;
    }
    .bloco_content{
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }
    .bloco_content_image_div{
        width: 100%;
    }
    .span_de_confirmacao{
        left: 20%;
        right: 20%;
        top: 20%;
    }
}

@media screen and (max-width:590px) {

    .span_de_confirmacao{
        left: 10%;
        right: 10%;
        top: 30%;
    }
    
}
    