.logo{
    width: 2.5rem;
    height: 2.5rem;
    position: fixed;
    right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 40px;
    background-color: #25D366;
    padding: 8px;
    z-index: 2;
    border-radius:50%;
}

.logo a{
    color: white;
    height: 2rem;
    font-size: 2rem;
    display: inline;
}


@media screen and (max-width:590px) {

    .logo{
        width: 3rem;
        height: 3rem;
    }
    
    .logo a{
        height: 2.5rem;
        font-size: 2.5rem;
    }
    
}