.bloco_main{
    padding: var(--alinhamento-vertical) var(--alinhamento-horizontal);
   
}

.bloco_main_inner{
    display: flex;
    align-items: center;
}

.bloco_content{
    width: 50%;
}

.bloco_content_texts p:last-child{
    margin-bottom: 0;
}

.bloco_content_texts span{
   color: var(--main-dourado_1);
   font-weight: bold;
}

.bloco_content_image_div{
    width: 50%;
    margin-right: 40px;
    align-self: flex-start;
}

.email{
    font-weight: bold;
    word-break: break-all;
}
.bloco_content_p1, .bloco_content_p2 ,
.bloco_content_p3, .bloco_content_p4 ,.bloco_content_p5, .bloco_content h2, .bloco_content h3, .email{
    opacity: 0;
    transform: translateX(-10%);
}

.image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
    box-shadow: 2px 2px 8px rgb(124, 124, 124);
    opacity: 0;
    transform: translateX(-50%);
}

.animation_vertical{
    animation: animation_vertical 1s ease-out forwards;
}

@keyframes animation_vertical{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        transform: translateX(0);
        opacity: 1;
    }
}

@media screen and (max-width:900px) {

    .bloco_main_inner{
    flex-direction: column;
    }
    .bloco_content{
        width: 100%;
    }
    .bloco_content_image_div{
        width: 100%;
        margin-right: 0;
    }
}