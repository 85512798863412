.cabecalho{
    background: url(../../assets/image/imagem_cabecalho.jpg) center center/  cover no-repeat;
    height: 80vh;
}

.cabecalho_main{
    background-color: rgb(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.cabecalho_navegacao_main{
    position: fixed;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.0);
    z-index: 2;
}

.cabecalho_navegacao{
    padding: 40px var(--alinhamento-horizontal)  10px;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: stretch;
}

.nav_link_item_tag_a{
    color: white;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.3rem;
}

.nav_link_item:hover .nav_link_item_span{
    transform: scale(1);
}

.logo{
    width: 200px;
    align-self: center;
    transition: width 200ms linear;
    transform: translateY(50%);
    opacity: 0;
}

.nav_links{
    opacity: 0;
    visibility: hidden;
}

.nav_links_cell{
    display: none;
    visibility: hidden;
    flex-direction: column;
    align-items: center;
}

.nav_links_cell .nav_link_item{
    margin: 10px;
}

.nav_links_cell .nav_link_item .nav_link_item_tag_a{
    margin: 0 15px 5px;
}



.cabecalho_content_main{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 var(--alinhamento-horizontal);
    color: white;
    height: 100%;
}

.cabecalho_content{
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    height: 100%;
    align-items: end;
}

.cabecalho_titulo{
    width: 80%;
    margin: 0;
    grid-row: 2/3;
    transform: translateY(100%);
    opacity: 0;
}

.cabecalho_texto{
    align-self: flex-start;
    width: 60%;
    margin-bottom: 0;
    grid-row: 3/4;
    transform: translateY(100%);
    opacity: 0;
}

.button_container{
    background: var(--linear-gradient-dourado-90) ;
    grid-row: 4/5;
    align-self: flex-end;
    justify-self: center;
    margin-bottom: 40px;
    border-radius: 35px;
    transform: translateY(100%);
    opacity: 0;
}

.button{
    border: none;
    padding: 20px 40px;
    background-color: transparent;
    
}

.nav_link_item{
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 10px;
}

.nav_link_item_span , .nav_link_item_span_cell{
    position: absolute;
    height: 2px;
    width: 100%;
    transform: scale(0);
    background: var(--linear-gradient-dourado-90);
    transition: transform 0.15s linear;
    bottom: 0;
}

.visible_span .nav_link_item_span {
    transform: scale(1);
}

.visible_span .nav_link_item_span_cell{
    transform: scale(1);
}


.background_animation{
    animation: background_animation 300ms linear forwards;
}
.background_animation_reverse{
    animation: background_animation_reverse 300ms linear forwards;
}

.opacity_animation{
    animation: opacity_animation 300ms linear forwards;
}

.opacity_animation_reverse{
    animation: opacity_animation_reverse 300ms linear forwards;
}

.button_hamburguer{
    display: none;
    opacity: 0;
    padding: 10px;
    visibility: hidden;
}

.button_hamburguer:active{
    background-color: rgb(224, 224, 224);
}

.barra1, .barra2 , .barra3{
    width: 20px;
    height: 2px;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 4.5px;
}

.barra1_class_animation{
    animation: animation_barra1 0.2s linear forwards;
}

.barra2_class_animation{
    animation: animation_barra2 0.2s linear forwards;
}

.barra3_class_animation{
    animation: animation_barra3 0.2s linear forwards;
}

.barra1_class_animation_reverse{
    animation: animation_barra1_reverse 0.2s linear forwards;
}

.barra2_class_animation_reverse{
    animation: animation_barra2_reverse 0.2s linear forwards;
}

.barra3_class_animation_reverse{
    animation: animation_barra3_reverse 0.2s linear forwards;
}

.animation_horizontal{
    animation: animation_horizontal 1s ease-out forwards;
}

@keyframes animation_horizontal{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes animation_barra1 {
    to{
        transform: rotate(44deg) translateY(5px) translateX(5px);
    }
    
}

@keyframes animation_barra2 {
    to{
        opacity: 0;
    }
    
}
@keyframes animation_barra3 {
    to{
        transform: rotate(-44deg) translateY(-5px) translateX(4px) ;
    }
    
}

@keyframes animation_barra1_reverse {
    0%{
        transform: rotate(44deg) translateY(5px) translateX(5px)
    }
    50%{
        transform: rotate(22deg) translateY(2px) translateX(2px)
    }
    100%{
        transform: rotate(0) translateY(0) translateX(0);
    }
    
}

@keyframes animation_barra2_reverse {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
    
}
@keyframes animation_barra3_reverse {
    0%{
        transform: rotate(-44deg) translateY(-5px) translateX(4px) ;
    }
    50%{
        transform: rotate(-22deg) translateY(-2px) translateX(2px) ;
    }
    100%{
        transform: rotate(0) translateY(0) translateX(0);
    }
    
}

@keyframes background_animation{
    from{
        background-color: rgb(0, 0, 0, 0.0);
    }
    to{
        background-color: rgb(0, 0, 0, 0.8);
    }
}

@keyframes background_animation_reverse{
    from{
        background-color: rgb(0, 0, 0, 0.8);
    }
    to{
        background-color: rgb(0, 0, 0, 0.0);
    }
}

@keyframes opacity_animation{
    0%{
        visibility: visible;
        opacity: 0;
    }
    50%{
        visibility: visible;
        opacity: 0.5;
    }
    100%{
        visibility: visible;
        opacity: 1;
    }
}

@keyframes opacity_animation_reverse{
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 0;
    }
}


@media screen and (max-width:900px) {

    .button_hamburguer{
        display: block;
        align-self: center;
    }

    .nav_links{
        visibility: hidden;
        animation: none;
        display: none;
    }

    .nav_links_cell{
        display: flex;
        opacity: 0;
    }

    .cabecalho_content{
        display: grid;
        grid-template-rows: repeat(4, 1fr);
        height: 100%;
        align-items: end;
    }

    .cabecalho_titulo{
        width: 100%;
    }
    
    .cabecalho_texto{
        width: 80%;
    }
 
}

@media screen and (max-width:590px) {

    .logo{
        width: 180px;
    }

    .cabecalho{
        background: url(../../assets/image/imagem_cabecalho.jpg) center center/  cover no-repeat;
        height: 70vh;
    }

    
}
    


